
























































































































import AuthModule from '@/store/modules/AuthModule';
import { Component, Vue } from 'vue-property-decorator';

import accounterrors from '@/pages/account/components/AccountErrors.vue';
import router from '@/router';

@Component({
  components: {
    accounterrors,
  },
})
export default class LoginPage extends Vue {
  usernameoremail: string | null = null;
  password: string | null = null;
  showPassword = false;
  showValidateMessage = false;

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  async login() {
    if (!this.validateAll) {
      this.showValidateMessage = true;
    } else {
      this.showValidateMessage = false;
      await AuthModule.login({
        usernameoremail: this.usernameoremail!,
        password: this.password!,
      });
    }
  }

  get loginProcessing() {
    return AuthModule.loginProcessing;
  }

  get loginError() {
    return AuthModule.loginError;
  }

  get validateAll(): boolean {
    return (
      this.validateUsernameOrEmail === true &&
      this.validatePassword === true
    );
  }

  get validateUsernameOrEmail() {
    if (this.usernameoremail) {
      return this.usernameoremail.length > 0;
    } else if (this.showValidateMessage) {
      return false;
    } else {
      return null;
    }
  }

  get validatePassword() {
    if (this.password) {
      return this.password!.length > 0;
    } else if (this.showValidateMessage) {
      return false;
    } else {
      return null;
    }
  }

  get inputType() {
    return this.showPassword ? 'text' : 'password';
  }

  get getIcon() {
    return this.showPassword ? 'eye-slash' : 'eye';
  }
}
